import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Layout, Card } from "antd";
import moment from "moment";
import _ from "lodash";

const { Content } = Layout;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({}) => {
  const params = useParams();

  const [filterLoan, setFilterLoan] = useState("asdf");

  let mapBank = [
    { name: "caixa", id: "bankia" },
    { name: "bankinter", id: "bankinter" },
    { name: "bbva", id: "bbva" },
    { name: "caixa", id: "caixabank" },
    { name: "eurocajarural", id: "eurocaja" },
    { name: "ibercaja", id: "ibercaja" },
    { name: "kutxabank", id: "kutxabank" },
    { name: "liberbank", id: "liberbank" },
    { name: "pichincha", id: "pichincha" },
    { name: "sabadell", id: "sabadell" },
    { name: "santander", id: "santander" },
    { name: "deutschebank", id: "deutsche" },
    {name:"ingdirect", id: "ing"}
   
  ];

  useEffect(() => {
    if (params.bankCode) {
      const bankCodeTmp = _.find(mapBank, function (b) {
        return b.id === params.bankCode;
      });
      if (bankCodeTmp) {
        setFilterLoan(bankCodeTmp.name);
      }
    }
  }, []);

  const myHtml = `
    <!DOCTYPE html>
    <html lang="es">
       <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Arcopay Widget</title>
       </head>
       <body>
          <div id='arcopayContainer'></div>
          <script type='text/javascript'>
             let parameters = {
               "id": "${params.loanId}",
               "action": "read",
               "countryCode": "ALL",
               "banksShown": "ALL",
               "banksShownV3": "${filterLoan}",
               "defaultBank": "${filterLoan}",
               "autoStart": 0,
               "defaultLanguage": "ES",
               "showSandbox": 0,
               "showSplashScreen": 0,
               "showCheckTerms": 0,
               "URLredirectAfterOK": "",
               "URLredirectAfterKO": "",
               "dailyFrequency": 4,
               "validUntil": "${moment().add(1, "month").format("DD-MM-YYYY")}",
               "paymentType": "normal",
               "amount": 0.01,
               "currency": "EUR",
               "sourceIBAN": "",
               "sourceCreditorName": "",
               "destinationIBAN": "ES4400490001532110022225",
               "destinationCreditorName": "Cruz Roja",
               "paymentDescription": "",
               "firstQuotaDay": "",
               "frequency": "",
               "numberOfQuotas": ""
             };
          </script>
          <script>
             function receiveFromFrame(ev) {
                 if (ev.data != ''&& ev.data.code != undefined && ev.data.message != undefined) {
                     let codigo = ev.data.code;
                     let mensaje = ev.data.message;
                     let widgetContainer = document.getElementById('arcopayContainer');
                     
                     switch (codigo) {
                         case 200:
                             if (parameters.action == 'read') {
                              if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                                Android.showToast();
                              }else {
                                window.webkit.messageHandlers.didCompletedProcess.postMessage(true);

                              }

                                  
                                 document.getElementById('frameResponse').innerHTML = 'Lectura finalizada correctamente.';
                             } else {
                                 document.getElementById('frameResponse').innerHTML = 'Pago finalizado correctamente.';
                             }
                             break;
             case 9999: // Cambio en el tamaño del widget
                             widgetContainer.style.height = mensaje + 'px';
                             window.scrollTo(0, 0);
                             document.getElementById('iframeArcopay').contentWindow.postMessage({"action": "changeIframeHeight"}, 'https://www.afterbanks.com/');
                             break;
             
                         default:
                            window.webkit.messageHandlers.didCompletedProcess.postMessage(false);
                             document.getElementById('frameResponse').innerHTML = codigo - mensaje;
                             break;
                     }
                 }
             }
             window.addEventListener('message', receiveFromFrame, false);
          </script>
          <script type='text/javascript' src="https://static.afterbanks.com/appmain/PSD2ExternalForm/js/external.js"></script>
          
       </body>
    </html>

`;

  return (
    <iframe
      width={"100%"}
      height={"500px"}
      srcDoc={myHtml}
      style={{ marginTop: 50, border: "none" }}
    ></iframe>
  );
};
